import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      height={props.height || 54}
      viewBox="0 0 64 64"
      width={props.width || 54}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g data-name="Fill Outline">
        <path d="M3 26h46v18H3z" fill="#d0d8da" />
        <path
          d="M47 26v7a6 6 0 01-6 6H26.82a11.9 11.9 0 00-7.031-13H3v18h46V26zm-30 9v-5.738A8 8 0 0122.738 35zM9 44h16v9H9z"
          fill="#93999a"
        />
        <path d="M16 49h9v-5H9v9h3a4 4 0 014-4z" fill="#656b76" />
        <rect fill="#f9e109" height={22} rx={2} width={4} x={49} y={24} />
        <rect fill="#f9e109" height={22} rx={2} width={4} x={53} y={24} />
        <path d="M57 26h4v18h-4z" fill="#d0d8da" />
        <path
          d="M17 23a12 12 0 1012 12 12 12 0 00-12-12zm7.738 10H19v-5.738A8 8 0 0124.738 33zM15 27.262V33H9.262A8 8 0 0115 27.262zM9.262 37H15v5.738A8 8 0 019.262 37zM19 42.738V37h5.738A8 8 0 0119 42.738zM33 22h12v4H33zM36 16h6v6h-6z"
          fill="#f9e109"
        />
        <circle cx={39} cy={11} fill="#84d2f4" r={8} />
        <path
          d="M42 16a7.993 7.993 0 01-6.951-11.951 8 8 0 1010.9 10.9A7.959 7.959 0 0142 16z"
          fill="#57b7eb"
        />
        <circle cx={39} cy={11} fill="#57b7eb" r={6} />
        <path
          d="M39 5a6 6 0 106 6 6 6 0 00-6-6zm3.741 9.741a6 6 0 01-7.482-7.482 6 6 0 017.482 7.482z"
          fill="#4891d3"
        />
        <rect fill="#f9e109" height={4} rx={2} width={20} x={7} y={53} />
        <rect fill="#f9e109" height={4} rx={2} width={20} x={7} y={57} />
        <circle cx={39} cy={15} fill="#f9e109" r={2} />
        <g fill="#fcbc04">
          <path d="M41 15a5.964 5.964 0 01-3.59-1.2A1.979 1.979 0 0037 15a2 2 0 004 0zM55 42V24a2 2 0 00-2 2v18a2 2 0 004 0 2 2 0 01-2-2zM51 42V24a2 2 0 00-2 2v18a2 2 0 004 0 2 2 0 01-2-2zM11 55h16a2 2 0 01-2 2H9a2 2 0 010-4 2 2 0 002 2zM11 59h16a2 2 0 01-2 2H9a2 2 0 010-4 2 2 0 002 2z" />
        </g>
        <path d="M59 37V26h-2v18h4v-5a2 2 0 01-2-2z" fill="#93999a" />
        <path
          d="M17 23a12 12 0 1012 12H17zm-2 19.738A8 8 0 019.262 37H15zM15 33H9.262A8 8 0 0115 27.262zm4 4h5.738A8 8 0 0119 42.738z"
          fill="#fcbc04"
        />
        <circle cx={17} cy={35} fill="#f8664f" r={4} />
        <path
          d="M19 37a3.985 3.985 0 01-3.64-5.64 3.995 3.995 0 105.28 5.28A3.961 3.961 0 0119 37z"
          fill="#cc5144"
        />
        <g data-name="Outline copy">
          <g fill="#323232">
            <path d="M61 43h-3v-4a1 1 0 00-2 0v5a1 1 0 01-2 0V26a1 1 0 012 0v9a1 1 0 002 0v-8h3a1 1 0 000-2h-3.184A2.972 2.972 0 0053 23.78 2.972 2.972 0 0048.184 25H46v-3a1 1 0 00-1-1h-2v-1.95a8.97 8.97 0 003.747-12.616 1 1 0 10-1.722 1.017 7.026 7.026 0 11-2.476-2.475 1 1 0 001.017-1.723A8.991 8.991 0 1035 19.05V21h-2a1 1 0 00-1 1v3h-6.718a12.933 12.933 0 00-1.864-1.3 1 1 0 00-.989 1.738 11.155 11.155 0 11-3.444-1.253 1 1 0 10.359-1.966A12.868 12.868 0 008.705 25H3a1 1 0 000 2h3.774a12.923 12.923 0 000 16H3a1 1 0 000 2h5v7.184A2.972 2.972 0 006.78 57 2.987 2.987 0 009 62h8a1 1 0 000-2H9a1 1 0 010-2h16a1 1 0 010 2h-4a1 1 0 000 2h4a2.987 2.987 0 002.22-5A2.972 2.972 0 0026 52.184v-7.825A12.945 12.945 0 0027.231 27H48v16H29.919a1 1 0 100 2h18.265A2.972 2.972 0 0053 46.22 2.972 2.972 0 0057.816 45H61a1 1 0 000-2zM9 56a1 1 0 010-2h16a1 1 0 010 2zm15-4H10v-6.063a12.907 12.907 0 0014 0zm13-32.232a8.737 8.737 0 004 0V21h-4zM34 25v-2h10v2zm17 20a1 1 0 01-1-1V26a1 1 0 012 0v18a1 1 0 01-1 1z" />
            <path d="M25.528 33.612a1 1 0 00.178-.862 8.989 8.989 0 00-6.456-6.457 1.009 1.009 0 00-.862.178 1 1 0 00-.388.791V30.1a5 5 0 00-2 0v-2.838a1 1 0 00-1.25-.969 8.989 8.989 0 00-6.456 6.457A1 1 0 009.262 34H12.1a5 5 0 000 2H9.262a1 1 0 00-.968 1.25 8.989 8.989 0 006.456 6.457 1.019 1.019 0 00.25.031 1 1 0 001-1V39.9a5 5 0 002 0v2.839a1 1 0 001 1 1.019 1.019 0 00.25-.031 8.989 8.989 0 006.456-6.457A1 1 0 0024.738 36H21.9a5 5 0 000-2h2.839a1 1 0 00.789-.388zM10.683 32A6.991 6.991 0 0114 28.683v2.343a4.993 4.993 0 00-.974.974zM14 41.317A6.991 6.991 0 0110.683 38h2.343a4.993 4.993 0 00.974.974zM14 35a3 3 0 113 3 3 3 0 01-3-3zm9.317 3A6.991 6.991 0 0120 41.317v-2.343a4.993 4.993 0 00.974-.974zm-2.343-6a4.993 4.993 0 00-.974-.974v-2.343A6.991 6.991 0 0123.317 32zM36.878 8.879a3 3 0 014.244 0 1 1 0 101.414-1.414 5.008 5.008 0 00-7.072 0 1 1 0 101.414 1.414z" />
            <circle cx={43} cy={11} r={1} />
            <circle cx={35} cy={11} r={1} />
            <path d="M42 15a3 3 0 00-2-2.816V10a1 1 0 00-2 0v2.184A2.995 2.995 0 1042 15zm-3 1a1 1 0 111-1 1 1 0 01-1 1z" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
