import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      height={props.height || 54}
      viewBox="0 0 256 256"
      width={props.width || 54}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M114.175 98.271h20.5v76.5h-20.5z" fill="#a4c9ff" />
      <path
        d="M134.675 176.771h-20.5a2 2 0 01-2-2v-76.5a2 2 0 012-2h20.5a2 2 0 012 2v76.5a2 2 0 01-2 2zm-18.5-4h16.5v-72.5h-16.5z"
        fill="#3762cc"
      />
      <path
        d="M188.783 243.271H60.067a8.142 8.142 0 01-8.142-8.142v-52.217a8.142 8.142 0 018.142-8.142h128.717a8.142 8.142 0 018.142 8.142v52.217a8.143 8.143 0 01-8.143 8.142z"
        fill="#6bdddd"
      />
      <path
        d="M178.669 183.021h-34.025v52h34.025a6 6 0 006-6v-40a6 6 0 00-6-6z"
        fill="#f9a7a7"
      />
      <path d="M104.451 183.021h40.193v52h-40.193z" fill="#ffea92" />
      <path
        d="M70.425 235.021h34.025v-52H70.425a6 6 0 00-6 6v40a6 6 0 006 6z"
        fill="#4671c6"
      />
      <path
        d="M188.783 245.271H60.066c-5.592 0-10.142-4.55-10.142-10.142v-52.217c0-5.592 4.549-10.142 10.142-10.142h128.717c5.592 0 10.142 4.55 10.142 10.142v52.217c0 5.592-4.55 10.142-10.142 10.142zm-128.717-68.5a6.149 6.149 0 00-6.142 6.142v52.217a6.149 6.149 0 006.142 6.142h128.717a6.149 6.149 0 006.142-6.142v-52.217a6.149 6.149 0 00-6.142-6.142z"
        fill="#3762cc"
      />
      <path d="M223.588 150.564h-49.56v-8.285h49.56z" fill="#4671c6" />
      <ellipse cx={240.627} cy={146.824} fill="#a4c9ff" rx={7.167} ry={20} />
      <ellipse cx={157.038} cy={146.824} fill="#a4c9ff" rx={7.167} ry={20} />
      <path
        d="M233.461 146.824c0-11.046 3.209-20 7.167-20-9.675 0-17.519 8.954-17.519 20s7.843 20 17.519 20c-3.959 0-7.167-8.954-7.167-20zM157.038 126.824c3.958 0 7.167 8.954 7.167 20s-3.209 20-7.167 20c9.675 0 17.519-8.954 17.519-20-.001-11.045-7.844-20-17.519-20z"
        fill="#4671c6"
      />
      <path
        d="M223.588 152.564h-49.56a2 2 0 01-2-2v-8.285a2 2 0 012-2h49.56a2 2 0 012 2v8.285a2 2 0 01-2 2zm-47.559-4h45.56v-4.285h-45.56zM240.627 168.824c-6.018 0-9.167-11.067-9.167-22s3.149-22 9.167-22 9.167 11.067 9.167 22-3.148 22-9.167 22zm0-40c-1.822 0-5.167 6.834-5.167 18s3.345 18 5.167 18c1.821 0 5.167-6.834 5.167-18s-3.345-18-5.167-18zM157.038 168.824c-6.018 0-9.167-11.067-9.167-22s3.148-22 9.167-22 9.167 11.067 9.167 22-3.149 22-9.167 22zm0-40c-1.821 0-5.167 6.834-5.167 18s3.345 18 5.167 18 5.167-6.834 5.167-18-3.346-18-5.167-18z"
        fill="#3762cc"
      />
      <path
        d="M240.627 168.824c-10.763 0-19.519-9.869-19.519-22s8.756-22 19.519-22a2 2 0 010 4c-1.822 0-5.167 6.834-5.167 18s3.345 18 5.167 18a2 2 0 010 4zm-6.284-38.457c-5.435 2.803-9.234 9.125-9.234 16.457s3.799 13.654 9.234 16.457c-1.905-4.237-2.882-10.368-2.882-16.457s.977-12.22 2.882-16.457zM157.038 168.824a2 2 0 010-4c1.822 0 5.167-6.834 5.167-18s-3.345-18-5.167-18a2 2 0 010-4c10.763 0 19.519 9.869 19.519 22s-8.757 22-19.519 22zm6.284-38.457c1.905 4.237 2.882 10.368 2.882 16.457s-.977 12.22-2.882 16.457c5.435-2.803 9.234-9.125 9.234-16.457s-3.799-13.654-9.234-16.457z"
        fill="#3762cc"
      />
      <path d="M174.029 27.228h49.56v8.285h-49.56z" fill="#4671c6" />
      <path
        d="M223.588 22.358v18.024a1.441 1.441 0 001.991 1.332l21.803-9.012c1.187-.491 1.187-2.172 0-2.663l-21.803-9.012a1.44 1.44 0 00-1.991 1.331zM158.481 12.729h-16.872a1.44 1.44 0 00-1.441 1.441v10.043c0 .409.174.799.478 1.072l5.549 4.98a1.441 1.441 0 010 2.145l-5.549 4.98a1.438 1.438 0 00-.478 1.072v10.043a1.44 1.44 0 001.441 1.441h16.872c1.641 0 3.205-.7 4.298-1.924l11.249-12.594v-8.181l-11.249-12.594a5.763 5.763 0 00-4.298-1.924z"
        fill="#6bdddd"
      />
      <path
        d="M223.588 37.513h-49.56a2 2 0 01-2-2v-8.285a2 2 0 012-2h49.56a2 2 0 012 2v8.285a2 2 0 01-2 2zm-47.559-4h45.56v-4.285h-45.56z"
        fill="#3762cc"
      />
      <path
        d="M225.028 43.824c-.67 0-1.335-.196-1.911-.581a3.435 3.435 0 01-1.529-2.86V22.358c0-1.15.571-2.22 1.528-2.86a3.43 3.43 0 013.227-.32l21.805 9.013c1.291.533 2.126 1.781 2.126 3.179s-.834 2.646-2.126 3.181l-21.804 9.013c-.425.173-.871.26-1.316.26zm.56-20.63v16.352l19.781-8.176zM158.481 51.945h-16.872a3.445 3.445 0 01-3.441-3.441V38.461c0-.976.417-1.909 1.143-2.561l5.085-4.563-5.085-4.563a3.445 3.445 0 01-1.143-2.561V14.17a3.444 3.444 0 013.441-3.44h16.872a7.77 7.77 0 015.791 2.592l11.249 12.593a2 2 0 01.508 1.332v8.182a2 2 0 01-.508 1.332l-11.25 12.593a7.774 7.774 0 01-5.79 2.591zm-16.313-4h16.313c1.07 0 2.093-.458 2.806-1.257l10.742-12.024V28.01l-10.741-12.024a3.768 3.768 0 00-2.807-1.256h-16.313v9.233l5.363 4.813a3.444 3.444 0 010 5.122l-5.363 4.813z"
        fill="#3762cc"
      />
      <path d="M74.274 77.771h108v20.5h-108z" fill="#a4c9ff" />
      <path
        d="M66.638 127.167H15.362a5.862 5.862 0 01-5.862-5.862v-.276a5.862 5.862 0 015.862-5.862h51.276a5.862 5.862 0 015.862 5.862v.276a5.863 5.863 0 01-5.862 5.862zM60.911 139.167H21.089a5.862 5.862 0 01-5.862-5.862v-.276a5.862 5.862 0 015.862-5.862H60.91a5.862 5.862 0 015.862 5.862v.276a5.861 5.861 0 01-5.861 5.862z"
        fill="#e0ebfc"
      />
      <path
        d="M96.315 212.32a13.42 13.42 0 00-.092-.701h.003c-1.381-11.472-9.706-17.752-11.838-19.185a.884.884 0 00-.994 0c-2.132 1.433-10.457 7.713-11.838 19.185h.017c-.099.655-.168 1.32-.168 2.004 0 7.167 5.59 12.977 12.486 12.977s12.486-5.81 12.486-12.977c0-.44-.021-.874-.062-1.303z"
        fill="#a4c9ff"
      />
      <g fill="#ffea92">
        <circle cx={168.253} cy={204.722} r={6.454} />
        <path d="M168.253 197.153a.606.606 0 01-.606-.606v-3.334a.606.606 0 111.212 0v3.334a.606.606 0 01-.606.606zM168.253 216.837a.606.606 0 01-.606-.606v-3.334a.606.606 0 111.212 0v3.334a.606.606 0 01-.606.606zM175.822 204.722c0-.335.271-.606.606-.606h3.334a.606.606 0 110 1.212h-3.334a.606.606 0 01-.606-.606zM156.138 204.722c0-.335.271-.606.606-.606h3.334a.606.606 0 110 1.212h-3.334a.606.606 0 01-.606-.606zM173.605 210.074a.605.605 0 01.857 0l2.357 2.357a.605.605 0 11-.857.857l-2.357-2.357a.605.605 0 010-.857zM159.686 196.155a.605.605 0 01.857 0l2.357 2.357a.605.605 0 11-.857.857l-2.357-2.357a.605.605 0 010-.857zM162.901 210.074a.605.605 0 010 .857l-2.357 2.357a.605.605 0 11-.857-.857l2.357-2.357a.605.605 0 01.857 0zM176.82 196.155a.605.605 0 010 .857l-2.357 2.357a.605.605 0 11-.857-.857l2.357-2.357a.607.607 0 01.857 0z" />
      </g>
      <path
        d="M169.804 209.019h-.171a5.105 5.105 0 00-6.779-4.564 5.424 5.424 0 00-10.599 2.13 6.062 6.062 0 00.792 12.071h16.756a4.819 4.819 0 00.001-9.637z"
        fill="#e0ebfc"
      />
      <path
        d="M128.736 215.324v-23.171a4.188 4.188 0 10-8.378 0v23.171a9.21 9.21 0 108.378 0z"
        fill="#a4c9ff"
      />
      <path
        d="M127.1 217.844a.376.376 0 01-.226-.342V192.23c0-1.281-1.002-2.381-2.284-2.404a2.327 2.327 0 00-2.371 2.327v25.349c0 .149-.09.28-.225.341a6.237 6.237 0 00-3.654 6.243c.266 3.02 2.728 5.439 5.752 5.654a6.238 6.238 0 006.686-6.215 6.237 6.237 0 00-3.678-5.681z"
        fill="#e0ebfc"
      />
      <circle cx={124.547} cy={223.526} fill="#a4c9ff" r={6.231} />
      <path
        d="M127.1 217.844a.376.376 0 01-.226-.342v-14.368h-4.654v14.368c0 .149-.09.28-.225.341a6.237 6.237 0 00-3.654 6.243c.266 3.02 2.728 5.439 5.752 5.654a6.238 6.238 0 006.686-6.215 6.24 6.24 0 00-3.679-5.681z"
        fill="#f9a7a7"
      />
      <path
        d="M129.431 195.567h-3.767a1.117 1.117 0 110-2.234h3.767a1.117 1.117 0 110 2.234zM129.431 201.152h-3.767a1.117 1.117 0 110-2.234h3.767a1.117 1.117 0 110 2.234zM129.431 207.11h-3.767a1.117 1.117 0 110-2.234h3.767a1.117 1.117 0 110 2.234zM129.431 213.068h-3.767a1.117 1.117 0 110-2.234h3.767a1.117 1.117 0 110 2.234z"
        fill="#f9cfcf"
      />
      <path
        d="M198.774 41.521c-9.113 0-16.5 7.387-16.5 16.5v61.75c0 9.113 7.387 16.5 16.5 16.5s16.5-7.387 16.5-16.5v-61.75c0-9.113-7.388-16.5-16.5-16.5z"
        fill="#e0ebfc"
      />
      <path d="M182.274 80.521v17.75h33v-20.5h-33z" fill="#f9a7a7" />
      <path
        d="M66.638 129.167H15.362c-4.335 0-7.862-3.527-7.862-7.862v-.276c0-4.335 3.527-7.861 7.862-7.861h51.276c4.335 0 7.862 3.526 7.862 7.861v.276c0 4.335-3.527 7.862-7.862 7.862zm-51.276-12a3.866 3.866 0 00-3.862 3.861v.276a3.866 3.866 0 003.862 3.862h51.276a3.866 3.866 0 003.862-3.862v-.276a3.866 3.866 0 00-3.862-3.861z"
        fill="#3762cc"
      />
      <path
        d="M60.911 141.167H21.089c-4.335 0-7.862-3.527-7.862-7.862v-.276c0-4.335 3.527-7.861 7.862-7.861H60.91c4.335 0 7.862 3.526 7.862 7.861v.276c.001 4.335-3.526 7.862-7.861 7.862zm-39.822-12a3.866 3.866 0 00-3.862 3.861v.276a3.866 3.866 0 003.862 3.862H60.91a3.866 3.866 0 003.862-3.862v-.276a3.866 3.866 0 00-3.862-3.861zM182.274 100.271h-108a2 2 0 01-2-2v-20.5a2 2 0 012-2h108a2 2 0 012 2v20.5a2 2 0 01-2 2zm-106-4h104v-16.5h-104z"
        fill="#3762cc"
      />
      <path
        d="M198.774 136.271c-1.34 0-2.635-.177-3.882-.479-.019.159-.048.315-.048.479v14.293a4 4 0 008 0v-14.293c0-.176-.03-.343-.052-.513-1.289.323-2.63.513-4.018.513zM198.774 41.521c1.364 0 2.682.184 3.95.496.021-.164.05-.326.05-.496V27.228a4 4 0 00-8 0v14.293c0 .17.029.332.05.496a16.489 16.489 0 013.95-.496z"
        fill="#f9a7a7"
      />
      <path
        d="M198.774 138.271c-10.201 0-18.5-8.299-18.5-18.5v-61.75c0-10.201 8.299-18.5 18.5-18.5s18.5 8.299 18.5 18.5v61.75c0 10.201-8.299 18.5-18.5 18.5zm0-94.75c-7.995 0-14.5 6.505-14.5 14.5v61.75c0 7.995 6.505 14.5 14.5 14.5s14.5-6.505 14.5-14.5v-61.75c0-7.996-6.505-14.5-14.5-14.5z"
        fill="#3762cc"
      />
      <path
        d="M198.843 156.563c-3.309 0-6-2.691-6-6V136.27c0-.216.022-.425.052-.635.069-.565.383-1.119.852-1.444.469-.324 1.06-.475 1.615-.344 2.36.571 4.592.561 6.943-.029a1.996 1.996 0 012.47 1.683c.043.312.068.536.068.77v14.293a6.006 6.006 0 01-6 5.999zm-2-18.4v12.4c0 1.103.897 2 2 2s2-.897 2-2v-12.416a17.43 17.43 0 01-4 .016zM194.824 44.017a2 2 0 01-1.985-1.752 5.312 5.312 0 01-.065-.744V27.228c0-3.309 2.691-6 6-6s6 2.691 6 6v14.293c0 .224-.024.439-.054.655-.071.566-.386 1.12-.857 1.443-.47.324-1.062.477-1.617.34-2.367-.582-4.579-.582-6.945 0a2.034 2.034 0 01-.477.058zm3.95-4.496c.658 0 1.326.039 2 .116V27.228c0-1.103-.897-2-2-2s-2 .897-2 2v12.409a17.629 17.629 0 012-.116z"
        fill="#3762cc"
      />
      <path
        d="M70.274 115.146H11.726a4 4 0 01-4-4v-52a4 4 0 014-4h58.548a4 4 0 014 4v52a4 4 0 01-4 4z"
        fill="#e0ebfc"
      />
      <path
        d="M70.274 117.146H11.726c-3.309 0-6-2.691-6-6v-52c0-3.309 2.691-6 6-6h58.548c3.309 0 6 2.691 6 6v52c0 3.308-2.691 6-6 6zm-58.548-60c-1.103 0-2 .897-2 2v52c0 1.103.897 2 2 2h58.548c1.103 0 2-.897 2-2v-52c0-1.103-.897-2-2-2z"
        fill="#3762cc"
      />
    </svg>
  )
}

export default SvgComponent
