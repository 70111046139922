import Altruism52 from "../images/icons/altruism_52.png"
import Economy52 from "../images/icons/economy_52.png"
import Worth52 from "../images/icons/worth_52.png"
import WeatherStation52 from "../images/icons/weather_station_52.png"
import ConcreteMixer52 from "../images/icons/concrete_mixer_52.png"
import AirConditioner52 from "../images/icons/air_conditioner_52.png"
import Plumbing52 from "../images/icons/plumbing_52.png"
import Paralax_Slider_01 from "../images/bg/bg-image-30.jpg"

import ConstructionSector from "../images/sectors/construction.png"
import EnvironmentSector from "../images/sectors/environment.png"
import MEPSector from "../images/sectors/mep.png"

import AbountEngineers from "../images/about/engineers.png"
import AbountVisionMission from "../images/about/that-unlocks.png"
import ContactHello from "../images/about/hello.png"

export const Icons = {
  Altruism52,
  Economy52,
  Worth52,
  WeatherStation52,
  ConcreteMixer52,
  AirConditioner52,
  Plumbing52,
}

export const Background = {
  Paralax_Slider_01,
}

export const Sectors = {
  Construction: ConstructionSector,
  Environment: EnvironmentSector,
  MEP: MEPSector,
}

export const About = {
  Engineers: AbountEngineers,
  VisionMission: AbountVisionMission,
  Hello: ContactHello,
}

export default {}
