import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      height={props.height || 54}
      viewBox="0 0 512 512"
      width={props.width || 54}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M126.102 441.802v-42.521l-23.754-11.254-17.433 11.254v42.521H64.448c-9.354 0-16.98 7.625-16.98 16.98 0 9.326 7.625 16.952 16.98 16.952h82.12c9.354 0 16.98-7.625 16.98-16.952 0-9.355-7.625-16.98-16.98-16.98z"
        fill="#375e7d"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M84.914 399.281h41.188v26.25H84.914z"
        fill="#2b4d66"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M221.403 51.942H107.28c-11.339 0-20.636 9.27-20.636 20.637v20.608l76.082 14.259 79.314-14.259V72.579c-.001-11.368-9.298-20.637-20.637-20.637zM342.896 51.942c-11.339 0-20.636 9.27-20.636 20.637v20.608l45.95 18.001 36.454-18.001V72.579c0-11.367-9.269-20.637-20.608-20.637z"
        fill="#375e7d"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M332.266 93.187H38.653v260.937c0 7.625 6.378 13.975 13.975 13.975h331.201c13.351-17.207 44.079-28.291 57.714-28.291 13.606 0 7.484.283 11.112.85V93.187H414.67z"
        fill="#ffd772"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M373.851 358.121H62.634c-7.597 0-13.975-6.378-13.975-13.975V57.923c0-10.233-9.269-19.503-20.58-19.503C16.741 38.42 7.5 47.69 7.5 59v285.146c0 30.303 24.803 55.136 55.134 55.136h296.023c.737-15.478 6.293-29.68 15.194-41.161zM442.676 330.653c15.789 2.438 29.934 9.922 40.677 20.75.17.17.312.34.482.51V57.923c0-12.416-9.269-21.657-20.58-21.657-11.339 0-20.58 9.241-20.58 20.58v273.807z"
        fill="#22a6fe"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M347.063 286.658c-9.439 0-17.093 7.654-17.093 17.093 0 9.44 7.654 17.093 17.093 17.093s17.093-7.654 17.093-17.093c0-9.44-7.653-17.093-17.093-17.093zM273.986 286.658c-9.439 0-17.065 7.654-17.065 17.093 0 9.44 7.625 17.093 17.065 17.093 9.439 0 17.093-7.654 17.093-17.093 0-9.44-7.654-17.093-17.093-17.093z"
        fill="#4fc9af"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M249.636 135.963v114.013c0 2.608 2.126 4.762 4.762 4.762h145.503c2.636 0 4.762-2.154 4.762-4.762V135.963a4.754 4.754 0 00-4.762-4.762H254.398a4.754 4.754 0 00-4.762 4.762zM194.332 131.201h-91.814c-8.731 0-15.874 7.115-15.874 15.846v91.845c0 8.703 7.143 15.846 15.874 15.846h91.814c8.731 0 15.846-7.143 15.846-15.846v-91.845c0-8.731-7.115-15.846-15.846-15.846z"
        fill="#a1d4ff"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M379.974 254.739v96.438c13.181-13.21 31.436-21.374 51.562-21.374 3.77 0 7.512.312 11.14.85V93.187h-62.703v38.014h19.928a4.754 4.754 0 014.762 4.762v114.013c0 2.608-2.126 4.762-4.762 4.762h-19.927z"
        fill="#fbba58"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M194.332 131.201h-91.814c-8.731 0-15.874 7.115-15.874 15.846v1.871h83.906c8.702 0 15.846 7.115 15.846 15.846v89.974h7.937c8.731 0 15.846-7.143 15.846-15.846v-91.845c-.001-8.731-7.116-15.846-15.847-15.846zM379.974 131.201H254.398a4.754 4.754 0 00-4.762 4.762v12.955h125.575c2.608 0 4.762 2.126 4.762 4.762v101.058h19.928c2.636 0 4.762-2.154 4.762-4.762V135.963a4.754 4.754 0 00-4.762-4.762z"
        fill="#75cef8"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M242.039 93.187V72.579c0-11.367-9.298-20.637-20.636-20.637h-24.718c11.367 0 20.636 9.27 20.636 20.637v20.608zM404.663 93.187V72.579c0-11.367-9.269-20.637-20.608-20.637h-24.718c11.339 0 20.636 9.27 20.636 20.637v20.608z"
        fill="#2b4d66"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M431.536 329.802c-23.471 0-44.362 11.084-57.685 28.319-8.901 11.481-14.457 25.683-15.194 41.16a71.15 71.15 0 00-.085 3.487c0 40.253 32.542 72.881 72.766 72.966h.396c39.94-.085 72.34-32.288 72.766-72.144v-1.616c-.227-19.701-8.249-37.56-21.147-50.572-10.743-10.829-24.888-18.312-40.677-20.75-3.628-.538-7.37-.85-11.14-.85z"
        fill="#375e7d"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M431.536 365.265c-19.531 0-35.575 14.939-37.361 34.017-.113 1.134-.142 2.324-.142 3.487 0 20.722 16.781 37.532 37.502 37.532s37.502-16.81 37.502-37.532c0-6.18-1.474-11.991-4.139-17.15-6.206-12.077-18.82-20.354-33.362-20.354z"
        fill="#f9f7f8"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M504.5 401.975c-.227-19.701-8.249-37.56-21.147-50.572-10.743-10.829-24.888-18.312-40.677-20.75-3.628-.539-7.37-.85-11.14-.85a72.817 72.817 0 00-34.214 8.504c12.246 3.628 23.074 10.574 31.436 19.815a68.103 68.103 0 015.754 7.257c13.266 1.049 24.605 8.986 30.388 20.24 2.665 5.159 4.139 10.97 4.139 17.15 0 19.049-14.202 34.782-32.57 37.22-8.164 13.323-20.778 23.67-35.745 28.943 9.298 4.337 19.672 6.775 30.614 6.803h.396c39.94-.085 72.34-32.288 72.766-72.144z"
        fill="#2b4d66"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M464.9 385.618c-5.783-11.254-17.121-19.191-30.388-20.24a34.823 34.823 0 00-2.976-.113c-3.685 0-7.257.539-10.602 1.531 7.512 3.515 13.663 9.468 17.461 16.838a37.163 37.163 0 014.025 13.89c.085 1.077.113 2.154.113 3.26 0 17.037-11.339 31.409-26.901 36.001a37.84 37.84 0 0015.902 3.515c1.672 0 3.317-.113 4.932-.312 18.369-2.438 32.57-18.171 32.57-37.22.002-6.179-1.472-11.991-4.136-17.15z"
        fill="#e5e4ec"
        fillRule="evenodd"
      />
      <path d="M156.518 185.47h-5.309l12.106-17.552a7.5 7.5 0 10-12.348-8.516L133.173 185.2c-1.107 1.622-4.048 5.93-1.557 10.645 1.114 2.11 3.531 4.626 8.715 4.626h5.283l-12.082 17.525a7.5 7.5 0 0012.35 8.514l17.755-25.753c1.115-1.626 4.08-5.947 1.598-10.661-1.111-2.111-3.525-4.626-8.717-4.626z" />
      <path d="M194.332 123.701h-91.814c-12.889 0-23.374 10.473-23.374 23.346v91.845c0 12.873 10.485 23.346 23.374 23.346h91.814c12.873 0 23.346-10.473 23.346-23.346v-91.845c0-12.873-10.473-23.346-23.346-23.346zm8.346 115.192c0 4.602-3.744 8.346-8.346 8.346h-91.814c-4.617 0-8.374-3.744-8.374-8.346v-91.845c0-4.602 3.757-8.346 8.374-8.346h91.814c4.602 0 8.346 3.744 8.346 8.346zM185.545 279.923h-74.24c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h74.24c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5zM185.545 312.607h-74.24c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h74.24c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5zM273.986 279.158c-13.546 0-24.565 11.033-24.565 24.593 0 13.561 11.02 24.594 24.565 24.594 13.561 0 24.593-11.033 24.593-24.594s-11.032-24.593-24.593-24.593zm0 34.187c-5.274 0-9.565-4.304-9.565-9.594s4.291-9.593 9.565-9.593c5.289 0 9.593 4.304 9.593 9.593 0 5.29-4.304 9.594-9.593 9.594zM347.063 279.158c-13.561 0-24.593 11.033-24.593 24.593 0 13.561 11.032 24.594 24.593 24.594s24.593-11.033 24.593-24.594-11.032-24.593-24.593-24.593zm0 34.187c-5.289 0-9.593-4.304-9.593-9.594s4.304-9.593 9.593-9.593 9.593 4.304 9.593 9.593c0 5.29-4.303 9.594-9.593 9.594zM307.491 247.239h-50.355V138.701h140.027v108.538h-55.061c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h57.799c6.761 0 12.262-5.501 12.262-12.263V135.963c0-6.761-5.501-12.262-12.262-12.262H254.398c-6.762 0-12.263 5.501-12.263 12.262v114.013c0 6.762 5.501 12.263 12.263 12.263h53.093c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5z" />
      <path d="M282.26 171.4a7.41 7.41 0 005.262 2.183 7.498 7.498 0 005.317-2.211c2.929-2.929 2.9-7.706-.028-10.635a7.5 7.5 0 00-10.607 10.606zM333.844 162.686a7.5 7.5 0 00-13.417 6.708l.028.057a7.451 7.451 0 006.68 4.125 7.513 7.513 0 003.368-.799c3.706-1.853 5.193-6.386 3.341-10.091zM361.488 171.4a7.41 7.41 0 005.262 2.183 7.498 7.498 0 005.317-2.211c2.929-2.929 2.9-7.706-.028-10.635a7.5 7.5 0 00-10.607 10.606zM282.203 198.245l.057.057a7.41 7.41 0 005.262 2.183 7.498 7.498 0 005.317-2.211c2.929-2.929 2.9-7.706-.028-10.635a7.5 7.5 0 00-10.607 0 7.498 7.498 0 00-.001 10.606zM323.781 186.233a7.5 7.5 0 00-3.354 10.062l.028.057a7.451 7.451 0 006.68 4.125 7.513 7.513 0 003.368-.799c3.705-1.852 5.192-6.386 3.34-10.09a7.497 7.497 0 00-10.062-3.355zM361.432 198.245l.057.057a7.41 7.41 0 005.262 2.183 7.498 7.498 0 005.317-2.211c2.929-2.929 2.9-7.706-.028-10.635a7.5 7.5 0 00-10.607 0 7.498 7.498 0 00-.001 10.606zM283.363 226.151a7.494 7.494 0 0010.395-2.107l.057-.085a7.5 7.5 0 10-12.502-8.287l-.057.085a7.498 7.498 0 002.107 10.394zM327.137 227.402a7.504 7.504 0 007.118-5.143l.028-.085c1.303-3.932-.842-8.133-4.773-9.436-3.934-1.3-8.19.87-9.493 4.803a7.5 7.5 0 007.12 9.861zM362.592 226.151a7.494 7.494 0 0010.395-2.107l.057-.085a7.5 7.5 0 10-12.502-8.287l-.057.085a7.497 7.497 0 002.107 10.394z" />
      <path d="M491.335 348.996V57.923c0-16.35-12.334-29.158-28.079-29.158-15.483 0-28.08 12.597-28.08 28.081v28.841h-23.013V72.579c0-15.515-12.609-28.137-28.107-28.137h-41.159c-15.515 0-28.137 12.622-28.137 28.137v13.108h-65.221V72.579c0-15.515-12.622-28.137-28.136-28.137H107.28c-15.515 0-28.137 12.622-28.137 28.137v13.108H56.159V57.923c0-14.637-12.858-27.003-28.079-27.003C12.597 30.92 0 43.517 0 59v145.705c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5V59c0-7.212 5.867-13.08 13.08-13.08 6.845 0 13.079 5.721 13.079 12.003v286.223c0 11.641 9.834 21.475 21.475 21.475h297.561a79.816 79.816 0 00-8.353 26.161H62.634C36.368 391.782 15 370.413 15 344.146V239.317c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v104.829c0 34.538 28.098 62.636 62.634 62.636h14.78v27.521H64.448c-13.498 0-24.479 10.982-24.479 24.48 0 13.483 10.981 24.452 24.479 24.452h82.12c13.498 0 24.479-10.969 24.479-24.452 0-13.499-10.981-24.48-24.479-24.48h-12.967v-27.521h217.573c2.099 42.512 37.34 76.453 80.362 76.453 44.368 0 80.464-36.097 80.464-80.466 0-20.655-7.828-39.511-20.665-53.773zm-41.159-292.15c0-7.213 5.868-13.081 13.08-13.081 7.456 0 13.079 6.086 13.079 14.158v278.038a80.066 80.066 0 00-26.159-11.456zM329.76 72.579c0-7.244 5.894-13.137 13.137-13.137h41.159c7.228 0 13.107 5.893 13.107 13.137v13.108H329.76zm-235.616 0c0-7.244 5.894-13.137 13.137-13.137h114.123c7.243 0 13.136 5.893 13.136 13.137v13.108H94.144zm-31.51 278.042c-3.449 0-6.475-3.026-6.475-6.475V100.687h379.017v221.708a81.38 81.38 0 00-3.64-.092c-24.486 0-46.437 11.008-61.207 28.319H62.634zm83.934 98.681c5.227 0 9.479 4.253 9.479 9.48 0 5.211-4.253 9.452-9.479 9.452h-82.12c-5.227 0-9.479-4.24-9.479-9.452 0-5.228 4.253-9.48 9.479-9.48h20.466a7.5 7.5 0 007.5-7.5v-35.021h26.188v35.021a7.5 7.5 0 007.5 7.5zm284.968 18.932c-36.097 0-65.464-29.368-65.464-65.466s29.367-65.466 65.464-65.466S497 366.67 497 402.768s-29.367 65.466-65.464 65.466z" />
      <path d="M431.536 357.765c-24.814 0-45.003 20.188-45.003 45.003 0 24.831 20.188 45.032 45.003 45.032s45.002-20.201 45.002-45.032c0-24.815-20.187-45.003-45.002-45.003zm0 75.035c-16.544 0-30.003-13.472-30.003-30.032 0-16.544 13.459-30.003 30.003-30.003 16.543 0 30.002 13.459 30.002 30.003 0 16.56-13.459 30.032-30.002 30.032z" />
    </svg>
  )
}

export default SvgComponent
