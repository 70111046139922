import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      height={props.height || 54}
      viewBox="0 0 64 64"
      width={props.width || 54}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M36 54v8h-6V51.89A7.15 7.15 0 0035.08 54z" fill="#fcd770" />
      <path
        d="M32 48h-4a7.12 7.12 0 002 3.89V62h-7v-4h1c.55 0 1.05-.22 1.41-.59.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2h-1V31c4.83 1.12 8.77 4.25 11 8.4V48zM15 58v4H8v-4.84c1.22.54 2.58.84 4 .84z"
        fill="#fcd770"
      />
      <path
        d="M17 54v4h-5c-1.42 0-2.78-.3-4-.84C4.47 55.62 2 52.1 2 48v-.66C2 39.53 7.39 32.76 15 31v22.55z"
        fill="#fcd770"
      />
      <path
        d="M17 58h6v4h-8v-4zM23 42v12c0-1.1-.9-2-2-2h-1.17c-.53 0-1.04.21-1.42.59L17 54l-2-.45V42l4-5z"
        fill="#e6e9ed"
      />
      <path d="M23 31v11l-4-5zM15 31l4 6-4 5z" fill="#ccd1d9" />
      <path
        d="M58 48h4a7.186 7.186 0 01-2.44 4.3 7.19 7.19 0 01-4.64 1.7H49c0-.55-.22-1.05-.59-1.41-.36-.37-.86-.59-1.41-.59h-4c-1.1 0-2 .9-2 2h-5.92c-1.97 0-3.78-.8-5.08-2.11A7.12 7.12 0 0128 48h28z"
        fill="#aab2bd"
      />
      <path d="M56 36v12H46V36h8z" fill="#d3a06c" />
      <path d="M54 20v16h-8v-4h-8V20h12z" fill="#dfb28b" />
      <path d="M38 32h8v16H34V32z" fill="#e6e9ed" />
      <path
        d="M48.41 52.59c.37.36.59.86.59 1.41 0 1.1-.45 2.1-1.17 2.83C47.1 57.55 46.1 58 45 58s-2.1-.45-2.83-1.17C41.45 56.1 41 55.1 41 54s.9-2 2-2h4c.55 0 1.05.22 1.41.59zM24 54c1.1 0 2 .9 2 2 0 .55-.22 1.05-.59 1.41-.36.37-.86.59-1.41.59h-7v-4l1.41-1.41c.38-.38.89-.59 1.42-.59H21c1.1 0 2 .9 2 2zM29.12 19.88A2.996 2.996 0 0127 25h-1.13c.09-.32.13-.65.13-1v-5h1c.83 0 1.58.34 2.12.88zM22 28l1 3-4 6-4-6 1-3z"
        fill="#f0d0b4"
      />
      <path
        d="M26 19v5c0 .35-.04.68-.13 1-.44 1.73-2.01 3-3.87 3h-6c-1.86 0-3.43-1.27-3.87-3-.09-.32-.13-.65-.13-1v-8h14z"
        fill="#f0d0b4"
      />
      <path
        d="M12.13 25H11c-.83 0-1.58-.34-2.12-.88S8 22.83 8 22c0-1.66 1.34-3 3-3h1v5c0 .35.04.68.13 1z"
        fill="#f0d0b4"
      />
      <path
        d="M30 16v6c0-.83-.34-1.58-.88-2.12S27.83 19 27 19h-1v-3zM11 19c-1.66 0-3 1.34-3 3v-6h4v3z"
        fill="#656d78"
      />
      <path
        d="M29.96 12H21V2.18c2.08.38 3.95 1.35 5.44 2.72 1.96 1.8 3.26 4.3 3.52 7.1zM17 2.19V12H8.04C8.49 7.06 12.2 3.06 17 2.18z"
        fill="#ff826e"
      />
      <path
        d="M21 2.19V12h-4V2.18c.65-.12 1.32-.18 2-.18s1.35.06 2 .18zM7 12h24c.55 0 1 .45 1 1v2c0 .55-.45 1-1 1H7c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1z"
        fill="#fc6e51"
      />
      <g>
        <path d="M59.477 47l3.451-8.628-1.857-.743L57.323 47H57V35h-2V19h-4v-4h-2v4h-2v-6h-2v6h-2v-4h-2v4h-4v12h-4v5.129a17.767 17.767 0 00-9.225-5.968l-.454-1.361a5.005 5.005 0 003.255-2.8H27c2.206 0 4-1.794 4-4v-5c1.103 0 2-.897 2-2v-2c0-1.103-.897-2-2-2h-.174C29.869 5.24 24.943 1 19 1S8.131 5.24 7.174 11H7c-1.103 0-2 .897-2 2v2c0 1.103.897 2 2 2v5c0 2.206 1.794 4 4 4h.424a5.004 5.004 0 003.255 2.8l-.454 1.361C6.41 32.222 1 39.208 1 47.342V48c0 4.264 2.444 7.961 6 9.786V63h30v-8h3.101c.465 2.279 2.484 4 4.899 4s4.434-1.721 4.899-4h5.019a8.155 8.155 0 008.069-6.835L63.181 47zM47 47V37h8v10zm-8-26h14v14h-6v-4h-8zm6 12v14H35V33zm-12 6.676V47h-.323l-3.749-9.372-1.857.743L30.523 47H26.82l.194 1.165A8.161 8.161 0 0029 52.29V61h-5v-2c1.654 0 3-1.346 3-3s-1.346-3-3-3V32.327a15.762 15.762 0 019 7.349zM21.279 29l.617 1.852L19 35.197l-2.897-4.345.618-1.852zM22 39.149l-1.762-2.203L22 34.303zm-6 0v-4.846l1.762 2.643zm0 3.201l3-3.75 3 3.75v8.826A2.99 2.99 0 0021 51h-1.171c-.801 0-1.555.312-2.122.879l-1.026 1.026-.681-.152zm2 12.064l1.121-1.121a.996.996 0 01.708-.293H21c.551 0 1 .449 1 1v1h2c.551 0 1 .449 1 1s-.449 1-1 1h-6zM22 59v2h-6v-2zm5-35v-4c1.103 0 2 .897 2 2s-.897 2-2 2zm2-5.444A3.959 3.959 0 0027 18v-1h2zM28.796 11H22V3.458A9.914 9.914 0 0128.796 11zM20 3.05V11h-2V3.05c.329-.032.662-.05 1-.05s.671.018 1 .05zm-4 .408V11H9.204A9.914 9.914 0 0116 3.458zM6.999 13H31v2H7zM11 17v1c-.732 0-1.409.212-2 .556V17zm-2 5c0-1.103.897-2 2-2v4c-1.103 0-2-.897-2-2zm4 2v-7h12v7c0 1.654-1.346 3-3 3h-6c-1.654 0-3-1.346-3-3zM3 48v-.658c0-6.96 4.464-12.968 11-15.029V52.31l-1.085-.241A4.972 4.972 0 019 47.187V43H7v4.187a6.958 6.958 0 005.481 6.833l3.519.782V57h-4c-4.962 0-9-4.038-9-9zm6 10.574c.955.271 1.959.426 3 .426h2v2H9zM31 61v-7.086a8.175 8.175 0 004 1.083V61zm14-4c-1.654 0-3-1.346-3-3 0-.551.449-1 1-1h4c.551 0 1 .449 1 1 0 1.654-1.346 3-3 3zm9.917-4h-5.102a2.996 2.996 0 00-2.816-2h-4a2.996 2.996 0 00-2.816 2h-5.102a6.159 6.159 0 01-5.785-4H60.7a6.153 6.153 0 01-5.783 4z" />
        <path d="M45 23h2v2h-2zM41 23h2v2h-2zM49 23h2v2h-2zM49 27h2v2h-2zM41 27h2v2h-2zM45 27h2v2h-2zM49 31h2v2h-2zM37 35h2v10h-2zM41 35h2v10h-2zM49 39h4v2h-4zM49 43h4v2h-4z" />
      </g>
    </svg>
  )
}

export default SvgComponent
