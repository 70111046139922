import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      height={props.height || 54}
      viewBox="0 0 60 60"
      width={props.width || 54}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)">
          <path
            d="M36.9 28.63L36.44 30H1.56l-.46-1.37A2 2 0 013 26h32a2 2 0 011.9 2.63z"
            fill="#fec108"
          />
          <path
            d="M36.44 30l-3.21 9.63a2 2 0 01-1.9 1.37H6.67a2 2 0 01-1.9-1.37L1.56 30z"
            fill="#ffdf00"
          />
          <path
            d="M33.44 30l-3.21 9.63a2 2 0 01-1.9 1.37h3a2 2 0 001.9-1.37L36.44 30z"
            fill="#fec108"
          />
          <path
            d="M26.536 6.556L22.6 11.28a2 2 0 01-1.537.72h-4.126a2 2 0 01-1.537-.72l-3.936-4.724A2 2 0 0111 5.276V2a2 2 0 012-2h12a2 2 0 012 2v3.276a2 2 0 01-.464 1.28z"
            fill="#ffdf00"
          />
          <path
            d="M25 0h-3a2 2 0 012 2v3.276a2 2 0 01-.464 1.28L19.6 11.28a2 2 0 01-1.537.72h3a2 2 0 001.537-.72l3.936-4.724A2 2 0 0027 5.276V2a2 2 0 00-2-2z"
            fill="#fec108"
          />
          <circle cx={19} cy={6} fill="#9e9e9e" r={2} />
          <path
            d="M17 52c0 .335-.03.67-.09 1a5.993 5.993 0 01-11.82 0 5.857 5.857 0 01.72-4 5.989 5.989 0 0110.38 0c.535.909.815 1.945.81 3z"
            fill="#607d8b"
          />
          <path
            d="M16.19 49a5.961 5.961 0 00-6.69-2.8 5.989 5.989 0 013.69 2.8 5.857 5.857 0 01.72 4 5.976 5.976 0 01-4.41 4.787A5.906 5.906 0 0016.91 53a5.857 5.857 0 00-.72-4z"
            fill="#37474f"
          />
          <path
            d="M33 52c0 .335-.03.67-.09 1a5.993 5.993 0 01-11.82 0 5.857 5.857 0 01.72-4 5.989 5.989 0 0110.38 0c.535.909.815 1.945.81 3z"
            fill="#607d8b"
          />
          <path
            d="M32.19 49a5.961 5.961 0 00-6.69-2.8 5.989 5.989 0 013.69 2.8 5.857 5.857 0 01.72 4 5.976 5.976 0 01-4.41 4.787A5.906 5.906 0 0032.91 53a5.857 5.857 0 00-.72-4z"
            fill="#37474f"
          />
          <path
            d="M54 52c0 .335-.03.67-.09 1a5.993 5.993 0 01-11.82 0 5.857 5.857 0 01.72-4 5.989 5.989 0 0110.38 0c.534.91.814 1.945.81 3z"
            fill="#607d8b"
          />
          <path
            d="M53.19 49a5.961 5.961 0 00-6.69-2.8 5.989 5.989 0 013.69 2.8 5.884 5.884 0 01.72 4 5.976 5.976 0 01-4.41 4.787A5.906 5.906 0 0053.91 53a5.884 5.884 0 00-.72-4z"
            fill="#37474f"
          />
          <g fill="#7f8e94">
            <path d="M5 52c0 .335.03.67.09 1H1a1 1 0 01-1-1v-2a1 1 0 011-1h4.81A5.857 5.857 0 005 52zM21 52c0 .335.03.67.09 1h-4.18a5.857 5.857 0 00-.72-4h5.62a5.857 5.857 0 00-.81 3zM42 52c0 .335.03.67.09 1h-9.18a5.857 5.857 0 00-.72-4h10.62a5.857 5.857 0 00-.81 3zM58 50v2a1 1 0 01-1 1h-3.09a5.884 5.884 0 00-.72-4H57a1 1 0 011 1z" />
          </g>
          <path
            d="M56 31.51V49h-2.81a5.989 5.989 0 00-10.38 0H37V22a1.752 1.752 0 01.04-.4A2.014 2.014 0 0139 20h9.81c.732 0 1.406.399 1.76 1.04l5.19 9.51c.158.295.24.625.24.96z"
            fill="#ff9801"
          />
          <path
            d="M55.76 30.55l-5.19-9.51A2.011 2.011 0 0048.81 20h-3c.732 0 1.406.399 1.76 1.04l5.19 9.51c.158.295.24.625.24.96v17.2c.063.1.133.187.19.286H56V31.51c0-.335-.082-.665-.24-.96zM45 46a5.985 5.985 0 00-5.19 3h3a5.989 5.989 0 013.69-2.8A5.982 5.982 0 0045 46z"
            fill="#f57c00"
          />
          <path
            d="M37 41v8h-4.81a5.989 5.989 0 00-10.38 0h-5.62a5.989 5.989 0 00-10.38 0H1v-6a2 2 0 012-2z"
            fill="#ff9801"
          />
          <path
            d="M24 46a5.985 5.985 0 00-5.19 3h3a5.989 5.989 0 013.69-2.8A5.982 5.982 0 0024 46zM8 46a5.985 5.985 0 00-5.19 3h3a5.989 5.989 0 013.69-2.8A5.982 5.982 0 008 46zM34 42h3v7h-3z"
            fill="#f57c00"
          />
          <path
            d="M52.19 24H44a1 1 0 00-1 1v5a1 1 0 001 1h11.93"
            fill="#00bcd4"
          />
          <path d="M52.19 24h-3l3.74 7h3z" fill="#00838f" />
          <path d="M51 36h5v4h-5a1 1 0 01-1-1v-2a1 1 0 011-1z" fill="#ffdf00" />
          <path d="M53 36h3v4h-3z" fill="#fec108" />
          <circle cx={11} cy={52} fill="#9e9e9e" r={2} />
          <circle cx={27} cy={52} fill="#9e9e9e" r={2} />
          <circle cx={48} cy={52} fill="#9e9e9e" r={2} />
        </g>
        <g fill="#000">
          <g fillRule="nonzero">
            <path d="M12 50a3 3 0 100 6 3 3 0 000-6zm0 4a1 1 0 110-2 1 1 0 010 2zM28 50a3 3 0 100 6 3 3 0 000-6zm0 4a1 1 0 110-2 1 1 0 010 2zM49 50a3 3 0 100 6 3 3 0 000-6zm0 4a1 1 0 110-2 1 1 0 010 2z" />
            <path d="M58 49V32.51a3.558 3.558 0 00-.366-1.436l-5.188-9.511A3 3 0 0049.812 20H40a3 3 0 00-3 3v3.19a3 3 0 00-1-.19h-.806L22.68 21a3.823 3.823 0 001.212-1.989 3.9 3.9 0 00-2.154-4.439 1.3 1.3 0 01-.613-.572h.938c.889 0 1.731-.396 2.3-1.079L28.305 8.2a3 3 0 00.7-1.92V3a3 3 0 00-3-3H14a3 3 0 00-3 3v3.276c.001.703.249 1.384.7 1.924l3.937 4.723a2.992 2.992 0 002.3 1.077h1.1a3.192 3.192 0 001.849 2.376 1.889 1.889 0 011.062 2.171 1.929 1.929 0 01-1.457 1.4 2.086 2.086 0 01-1.777-.389A1.875 1.875 0 0118 18.092a1 1 0 00-2 0 3.85 3.85 0 001.336 2.9L4.807 26H4a3 3 0 00-2.846 3.949l3.667 11c.006.018.016.033.022.051H4a3 3 0 00-3 3v5.278A1.993 1.993 0 000 51v2a2 2 0 002 2h3.3a7 7 0 0013.4 0h2.6a7 7 0 0013.41 0h7.59a7 7 0 0013.41 0H58a2 2 0 002-2v-2a2 2 0 00-2-2zM17.937 12a1 1 0 01-.769-.36l-3.936-4.723A1.007 1.007 0 0113 6.276V3a1 1 0 011-1h12a1 1 0 011 1v3.276a1 1 0 01-.231.64l-3.938 4.725a1 1 0 01-.768.359zm34.651 14l2.727 5H45v-5zM20 22.077L29.807 26H10.193zM3.19 28.415c.445-.617 1.118-.352 1.785-.415H36a.989.989 0 01.811.415c.429.6 0 1.137-.091 1.585H3.281c-.093-.437-.519-.992-.091-1.585zM37 35.483V41h-1.844c.006-.018.016-.033.022-.051zM3.947 32h32.106l-2.773 8.316a1 1 0 01-.948.684H7.668a1 1 0 01-.948-.684zM5 53H2v-2h3.3a6.956 6.956 0 00-.3 2zm7 5a5 5 0 110-10 5 5 0 010 10zm9-5h-2a6.956 6.956 0 00-.3-2h2.6a6.956 6.956 0 00-.3 2zm7 5a5 5 0 110-10 5 5 0 010 10zm5.736-9a6.984 6.984 0 00-11.472 0h-4.528a6.984 6.984 0 00-11.472 0H3v-2h2a1 1 0 000-2H3v-1a1 1 0 011-1h33v6zM42 53h-7a6.956 6.956 0 00-.3-2h7.6a6.956 6.956 0 00-.3 2zm7 5a5 5 0 110-10 5 5 0 010 10zm5.736-9a6.984 6.984 0 00-11.472 0H39V23a1 1 0 011-1h9.812a1 1 0 01.878.521L51.5 24H45a2 2 0 00-2 2v5a2 2 0 002 2h11v3h-4a2 2 0 00-2 2v2a2 2 0 002 2h4v2h-2a1 1 0 000 2h2v3zM56 38v2h-4v-2zm2 15h-2a6.956 6.956 0 00-.3-2H58z" />
          </g>
          <path d="M46 36h-2a1 1 0 000 2h2a1 1 0 000-2z" />
          <path
            d="M23 7a3 3 0 10-6 0 3 3 0 006 0zm-3 1a1 1 0 110-2 1 1 0 010 2z"
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
