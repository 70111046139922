import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      height={props.height || 54}
      viewBox="0 0 256 256"
      width={props.width || 54}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M243.15 189.818H12.85a8.85 8.85 0 01-8.85-8.85V39.172a8.85 8.85 0 018.85-8.85h230.3a8.85 8.85 0 018.85 8.85v141.796a8.85 8.85 0 01-8.85 8.85z"
        fill="#f4efed"
      />
      <path
        d="M243.15 30.322H128v159.496h115.15a8.85 8.85 0 008.85-8.85V39.172a8.85 8.85 0 00-8.85-8.85z"
        fill="#fcc24c"
      />
      <path
        d="M141.526 69.23h9.313v23.49h-9.313zM163.46 49.23h9.313v43.49h-9.313zM185.395 59.23h9.313v33.49h-9.313zM207.329 69.23h9.313v23.49h-9.313zM229.263 49.23h9.313v43.49h-9.313z"
        fill="#576fa5"
      />
      <path
        d="M89.489 169.279H49.486c-13.543 0-24.561-11.019-24.561-24.562V75.422c0-13.543 11.019-24.562 24.561-24.562h2.879c13.544 0 24.562 11.019 24.562 24.562v12.196c0 6.927 5.635 12.562 12.562 12.562 13.543 0 24.562 11.019 24.562 24.562v19.976c0 13.543-11.019 24.561-24.562 24.561zM49.486 62.861c-6.927 0-12.561 5.635-12.561 12.562v69.295c0 6.927 5.635 12.562 12.561 12.562h40.003c6.927 0 12.562-5.636 12.562-12.562v-19.976c0-6.927-5.635-12.562-12.562-12.562-13.543 0-24.562-11.019-24.562-24.562V75.422c0-6.927-5.636-12.562-12.562-12.562h-2.879z"
        fill="#ff4397"
      />
      <path
        d="M243.15 30.322h-10a8.85 8.85 0 018.85 8.85v141.796a8.85 8.85 0 01-8.85 8.85h10a8.85 8.85 0 008.85-8.85V39.172a8.85 8.85 0 00-8.85-8.85z"
        fill="#dea42e"
      />
      <path
        d="M12.85 189.818h10a8.85 8.85 0 01-8.85-8.85V39.172a8.85 8.85 0 018.85-8.85h-10A8.85 8.85 0 004 39.172v141.796a8.85 8.85 0 008.85 8.85z"
        fill="#fff"
      />
      <path
        d="M208.901 225.678H47.099v-11.854a8.85 8.85 0 018.85-8.85h144.102a8.85 8.85 0 018.85 8.85z"
        fill="#7bac51"
      />
      <path
        d="M200.051 204.974h-10a8.85 8.85 0 018.85 8.85v11.855h10v-11.855a8.85 8.85 0 00-8.85-8.85z"
        fill="#5d8e33"
      />
      <path
        d="M55.949 204.974h10a8.85 8.85 0 00-8.85 8.85v11.855h-10v-11.855a8.85 8.85 0 018.85-8.85z"
        fill="#99ca6f"
      />
      <path d="M80.503 189.818h94.993v15.156H80.503z" fill="#5d8e33" />
      <g fill="#690589">
        <path d="M12 67.526a2 2 0 01-2-2v-12a2 2 0 014 0v12a2 2 0 01-2 2zM208.901 227.678H47.099a2 2 0 01-2-2v-11.854c0-5.983 4.867-10.851 10.851-10.851h144.102c5.983 0 10.851 4.867 10.851 10.851v11.854a2.003 2.003 0 01-2.002 2zm-159.802-4h157.803v-9.854a6.859 6.859 0 00-6.851-6.851H55.949a6.859 6.859 0 00-6.851 6.851v9.854z" />
        <path d="M175.497 206.974H80.503a2 2 0 01-2-2v-15.155a2 2 0 012-2h94.994a2 2 0 012 2v15.155a2 2 0 01-2 2zm-92.994-4h90.994v-11.155H82.503z" />
        <path d="M243.15 191.818H128a2 2 0 01-2-2V30.322a2 2 0 012-2h115.15c5.982 0 10.85 4.867 10.85 10.85v141.797c0 5.982-4.867 10.849-10.85 10.849zm-113.15-4h113.15a6.857 6.857 0 006.85-6.85V39.172a6.857 6.857 0 00-6.85-6.85H130z" />
        <path d="M243.15 191.818H12.85c-5.982 0-10.85-4.867-10.85-10.851V39.172c0-5.982 4.867-10.85 10.85-10.85h230.3c5.982 0 10.85 4.867 10.85 10.85v141.796c0 5.983-4.867 10.85-10.85 10.85zM12.85 32.322A6.857 6.857 0 006 39.172v141.796a6.858 6.858 0 006.85 6.851h230.3a6.858 6.858 0 006.85-6.851V39.172a6.857 6.857 0 00-6.85-6.85z" />
        <path d="M89.489 171.279H49.486c-14.646 0-26.562-11.916-26.562-26.562V75.422c0-14.646 11.915-26.562 26.562-26.562h2.879c14.646 0 26.562 11.915 26.562 26.562v12.196c0 5.823 4.738 10.562 10.562 10.562 14.646 0 26.562 11.915 26.562 26.562v19.976c0 14.645-11.915 26.561-26.562 26.561zM49.486 52.86c-12.44 0-22.562 10.121-22.562 22.562v69.295c0 12.441 10.121 22.562 22.562 22.562h40.003c12.44 0 22.562-10.121 22.562-22.562v-19.976c0-12.44-10.121-22.562-22.562-22.562-8.029 0-14.562-6.532-14.562-14.562V75.422c0-12.44-10.121-22.562-22.562-22.562zm40.003 106.419H49.486c-8.029 0-14.562-6.532-14.562-14.562V75.422c0-8.029 6.532-14.562 14.562-14.562h2.879c8.03 0 14.562 6.532 14.562 14.562v12.196c0 12.44 10.121 22.562 22.562 22.562 8.029 0 14.562 6.532 14.562 14.562v19.976c0 8.029-6.532 14.561-14.562 14.561zM49.486 64.86c-5.823 0-10.562 4.738-10.562 10.562v69.295c0 5.824 4.738 10.562 10.562 10.562h40.003c5.823 0 10.562-4.738 10.562-10.562v-19.976c0-5.823-4.738-10.562-10.562-10.562-14.646 0-26.562-11.915-26.562-26.562V75.422c0-5.823-4.738-10.562-10.562-10.562z" />
        <path d="M36.925 125.334h-12a2 2 0 010-4h12a2 2 0 010 4zM252 112.07H128a2 2 0 010-4h124a2 2 0 010 4zM150.839 94.721h-9.312a2 2 0 01-2-2V69.23a2 2 0 012-2h9.312a2 2 0 012 2v23.49a2 2 0 01-2 2.001zm-7.313-4h5.312V71.23h-5.312zM172.773 94.721h-9.313a2 2 0 01-2-2V49.23a2 2 0 012-2h9.313a2 2 0 012 2v43.49a2 2 0 01-2 2.001zm-7.313-4h5.313V51.23h-5.313zM194.707 94.721h-9.312a2 2 0 01-2-2V59.23a2 2 0 012-2h9.312a2 2 0 012 2v33.49a2 2 0 01-2 2.001zm-7.312-4h5.312V61.23h-5.312zM216.642 94.721h-9.312a2 2 0 01-2-2V69.23a2 2 0 012-2h9.312a2 2 0 012 2v23.49a2 2 0 01-2 2.001zm-7.313-4h5.312V71.23h-5.312zM238.575 94.721h-9.312a2 2 0 01-2-2V49.23a2 2 0 012-2h9.312a2 2 0 012 2v43.49a2 2 0 01-2 2.001zm-7.312-4h5.312V51.23h-5.312zM141.528 171.279a2 2 0 01-1.942-2.486l4.189-16.736c1.154-4.613 4.76-8.103 9.408-9.104s9.371.691 12.324 4.419a8.326 8.326 0 006.905 3.175 8.309 8.309 0 006.636-3.704l8.081-12.021a12.39 12.39 0 0110.184-5.479l.102-.001a12.38 12.38 0 0110.167 5.31l12.367 17.757c1.787 2.566 4.713 3.892 7.82 3.542 3.108-.349 5.666-2.287 6.841-5.186l2.111-5.209a1.999 1.999 0 113.707 1.501l-2.111 5.209c-1.735 4.281-5.512 7.145-10.103 7.659-4.594.507-8.908-1.442-11.548-5.231l-12.368-17.758c-1.593-2.287-4.097-3.595-6.88-3.595l-.073.001c-2.812.022-5.326 1.375-6.896 3.71l-8.081 12.021c-2.231 3.32-5.804 5.313-9.8 5.469-4.029.163-7.713-1.552-10.196-4.687-2.029-2.563-5.147-3.683-8.346-2.993-3.196.689-5.578 2.994-6.371 6.165l-4.189 16.736a1.998 1.998 0 01-1.938 1.516zM114.051 52.86H90.426a2 2 0 010-4h23.625a2 2 0 010 4zM114.051 64.86H90.426a2 2 0 010-4h23.625a2 2 0 010 4zM114.051 76.86H90.426a2 2 0 010-4h23.625a2 2 0 010 4z" />
      </g>
    </svg>
  )
}

export default SvgComponent
