import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      height={props.height || 54}
      viewBox="0 0 64 64"
      width={props.width || 54}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M42 50H3v11h39z" fill="#9c5f20" />
      <path
        d="M23.48 32.203A3.98 3.98 0 0024.145 30c0-2.369-2.197-4.295-4.587-3.941A3.961 3.961 0 0020.145 24a4 4 0 00-8 0c0 .756.222 1.456.587 2.059-2.39-.354-4.587 1.532-4.587 3.941 0 .723.239 1.394.504 2.044a1.776 1.776 0 00-.752.328c-3.015 2.419-3.156 5.398-1.66 8.796C8.047 45.28 11.099 50 16.145 50c5.76 0 8.951-6.508 10.526-11.169.207-.611.315-1.23.328-1.833.045-2.171-1.156-4.137-3.519-4.795z"
        fill="#94b020"
      />
      <path
        d="M44 43.043v-4.085A2.957 2.957 0 0041.043 36h-8.085A2.957 2.957 0 0030 38.957v4.085A2.957 2.957 0 0032.957 46h8.086A2.957 2.957 0 0044 43.043z"
        fill="#c4c4c4"
      />
      <circle cx={37} cy={41} fill="#c12929" r={2} />
      <circle cx={54} cy={10} fill="#edbf36" r={3} />
      <path d="M54 30c5 0 0-8 0-8s-5 8 0 8z" fill="#3699e8" />
      <circle cx={54} cy={41} fill="#f4c69d" r={5} />
      <circle cx={54} cy={59} fill="#c12929" r={2} />
      <path
        d="M26.671 35.831C25.095 40.492 21.904 47 16.145 47c-5.046 0-8.098-4.72-9.908-8.832-.346-.786-.6-1.548-.755-2.288-.405 1.615-.082 3.385.755 5.288C8.047 45.28 11.099 50 16.145 50c5.76 0 8.951-6.508 10.526-11.169.207-.611.315-1.23.328-1.833a5.313 5.313 0 00-.213-1.611c-.039.148-.065.296-.115.444z"
        fill="#788f1a"
      />
      <path
        d="M42 50l-2.555 1.437a71.364 71.364 0 01-28.814 8.899L3 61h39z"
        fill="#804e1a"
      />
      <path
        d="M41.043 44h-8.086A2.957 2.957 0 0130 41.043v2A2.957 2.957 0 0032.957 46h8.086A2.957 2.957 0 0044 43.043v-2A2.957 2.957 0 0141.043 44z"
        fill="#a6a6a6"
      />
      <path d="M43 61V50a1 1 0 00-1-1h-7v-2h6.042A3.962 3.962 0 0045 43.043v-4.086A3.962 3.962 0 0041.042 35h-8.085A3.961 3.961 0 0029 38.957v4.086A3.962 3.962 0 0032.958 47H33v2H21.599c2.422-1.932 4.46-5.238 6.02-9.849.237-.702.365-1.42.38-2.132v-.001c.048-2.363-1.154-4.358-3.141-5.353a4.97 4.97 0 00.287-1.666c0-1.426-.637-2.794-1.748-3.752a5.045 5.045 0 00-2.379-1.148c.084-.361.127-.73.127-1.1 0-2.757-2.243-5-5-5s-5 2.243-5 5c0 .368.043.736.127 1.097a5.037 5.037 0 00-2.39 1.146A4.966 4.966 0 007.145 30c0 .542.103 1.044.243 1.505-.04.028-.079.057-.117.087-3.11 2.496-3.766 5.854-1.949 9.98 1.491 3.388 3.245 5.863 5.24 7.428H3a1 1 0 00-1 1v11a1 1 0 001 1h39a1 1 0 001-1zM31 43.043v-4.086A1.96 1.96 0 0132.958 37h8.085A1.96 1.96 0 0143 38.957v4.086A1.96 1.96 0 0141.042 45h-8.085A1.96 1.96 0 0131 43.043zM7.152 40.766c-1.465-3.329-1.043-5.677 1.371-7.613.024-.02.118-.086.328-.129a1.002 1.002 0 00.723-1.358c-.266-.651-.43-1.157-.43-1.666 0-.854.38-1.671 1.043-2.243a3.018 3.018 0 012.397-.708 1 1 0 001.002-1.507A2.945 2.945 0 0113.143 24c0-1.654 1.346-3 3-3s3 1.346 3 3c0 .537-.149 1.057-.443 1.542-.2.332-.191.749.023 1.071s.594.488.979.436a2.983 2.983 0 012.386.714c.67.578 1.054 1.394 1.054 2.237 0 .587-.172 1.158-.499 1.651a1 1 0 00.565 1.514c1.784.497 2.827 1.922 2.788 3.812a5.131 5.131 0 01-.275 1.533c-1.507 4.455-4.192 9.702-8.58 10.405v-4.501l4.707-4.707-1.414-1.414-3.293 3.293v-4.172l4.707-4.707-1.414-1.414-3.293 3.293V28h-2v6.586l-3.293-3.293-1.414 1.414 4.707 4.707v4.172l-3.293-3.293-1.414 1.414 4.707 4.707v4.503c-3.126-.476-5.812-3.207-7.989-8.151zM41 60H4v-9h37z" />
      <path d="M37 38c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm0 4a1.001 1.001 0 010-2 1.001 1.001 0 010 2zM54 6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zM53 2h2v3h-2zM53 15h2v3h-2zM46 9h3v2h-3zM59 9h3v2h-3zM47.624 5.047l1.414-1.414 2.12 2.12-1.413 1.415zM56.813 14.236l1.414-1.414 2.121 2.12-1.414 1.415zM47.63 14.953l2.12-2.12 1.415 1.413-2.121 2.121zM56.821 5.764l2.121-2.121 1.414 1.414-2.12 2.12zM54 31c1.695 0 2.479-.772 2.838-1.421 1.376-2.484-1.417-7.19-1.99-8.109-.366-.584-1.33-.584-1.696 0-.574.919-3.367 5.625-1.99 8.109C51.521 30.228 52.305 31 54 31zm0-6.939c.852 1.72 1.539 3.735 1.088 4.549-.052.096-.215.39-1.088.39-.872 0-1.035-.294-1.089-.391-.45-.81.238-2.827 1.089-4.548zM55 38h-2v2.012l-1.978.006.006 2L53 42.012V44h2v-1.994L57.003 42l-.006-2-1.997.006z" />
      <path d="M54 35c-3.309 0-6 2.691-6 6s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6zm0 10c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zM55 56.184V51h-2v5.184A2.996 2.996 0 0051 59c0 1.654 1.346 3 3 3s3-1.346 3-3a2.996 2.996 0 00-2-2.816zM54 60a1.001 1.001 0 010-2 1.001 1.001 0 010 2zM41.707 9.707l3-3a.999.999 0 000-1.414l-3-3-1.414 1.414L41.586 5H37a1 1 0 00-1 1v26h2V7h3.586l-1.293 1.293z" />
    </svg>
  )
}

export default SvgComponent
